import * as React from "react"
import {FormEvent, FC} from 'react'
import {Contract} from 'ethers'

const MintForm: FC<{contracts: Map<string, Contract>}> = ({contracts}) => {
    const handleMintNFT = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const target = e.target as typeof e.target & {
            quantity: { value: number };
        };
        console.log(target.quantity.value)
        const nft = contracts.get('nft')

        try {
            const tx = await nft!.mint(target.quantity.value)
            console.log(tx)
        } catch(e) {
            console.log(e)
        }

    }
    return (
        <form onSubmit={handleMintNFT}>
            <label>
                Quantity:
                <input type="number" name="quantity" />
            </label>
            <input type="submit" value="Mint" />
        </form>
    )
}
export default MintForm