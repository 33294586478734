import * as React from "react"
import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'

import { graphql } from "gatsby"

import { hooks as metaMaskHooks, metaMask } from '../connectors/metamask'
import App from '../components/app'
import SEO from '../components/seo'

import '../style/index.scss'

const connectors: [MetaMask, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
]

// markup
const IndexPage = ({data}) => {
  return (
    <>
      <SEO {...data.site.siteMetadata}/>
      <Web3ReactProvider connectors={connectors}>
        <App/>
      </Web3ReactProvider>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

export default IndexPage
