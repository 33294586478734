import * as React from "react"
import {useEffect} from 'react'
import { useWeb3React } from '@web3-react/core'

const MetaMaskButton = () => {
    const {connector, hooks} = useWeb3React()
    const isActivating = hooks.useSelectedIsActivating(connector)

    useEffect(() => {
        connector.connectEagerly?.()
    }, [])

    return (
        <button onClick={isActivating ? undefined : ()=> {connector.activate()}} disabled={isActivating}>
            Connect
        </button>
    )
}
export default MetaMaskButton