import * as React from "react"
import Helmet from "react-helmet"

import ogpImage from '../images/ogp.png';


const SEO = ({title, description, siteUrl}: {title: string, description: string, siteUrl: string}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
        <meta property="og:title"       content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:url"         content={siteUrl}/>
        <meta name="twitter:card"       content={description}/>
        <meta property="og:image" content={`${siteUrl}${ogpImage}`} />
    </Helmet>
  )
}

export default SEO