import * as React from "react"
import {useEffect, useState} from 'react'
import { useWeb3React } from '@web3-react/core'

import {Contract, ethers} from 'ethers'

import MetaMaskButton from './connect_button'
import MintForm from './mint'

import ContractsABI from '../contracts'

// markup
const App = () => {
  const {connector, hooks} = useWeb3React()
  const isActive = hooks.useSelectedIsActive(connector)
  const provider = hooks.usePriorityProvider()

  const [contracts, setContracts] = useState<Map<string, Contract>>()

  useEffect(()=>{
    console.log(isActive, provider)
    if (!isActive || provider === undefined) {
        return
    }
    const signer = provider.getSigner()
    
    const tmpContracts = new Map<string, Contract>()
    for (const [key, value] of ContractsABI) {
        tmpContracts.set(key, new ethers.Contract(value.address, value.abi, signer))
    }
    setContracts(tmpContracts)
  }, [isActive, provider])
  return (
    <main>
        <div className="container">
          <h1>This is Sample Project</h1>
          <div className="card">
            {isActive && contracts !== undefined ? <MintForm contracts={contracts} /> : <MetaMaskButton />}
          </div>
        </div>   
    </main>
  )
}

export default App
